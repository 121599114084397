import request from '@/utils/request'


export function getList(query) {
  return request({
    url: '/job/list',
    method: 'get',
    params:query,

  })
}
export function getInfo(query) {
  return request({
    url: '/job/info',
    method: 'get',
      params:query,
  })
}

export function getOptionList(query) {
  return request({
    url: '/job/option-list',
    method: 'get',
      params:query,
  })
}
export function getType(query) {
  return request({
    url: '/job/type',
    method: 'get',
      params:query,
  })
}




