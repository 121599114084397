<template>
    <div class="join-components">
        <div class="wrap bc-white">


            <div class="floor1" style="background-color: #D8D8D8">
                <div class="p-t-50 text-center ">
                    <el-input class="search" v-model="listQuery.position"  placeholder="搜索工作岗位" style="width:200px;max-width:100%;height: 48px;" />
                    <el-button type="primary " style="border-radius: 0;vertical-align:bottom"  @click="handleSelect">
                        <img src="@/assets/imp_search.png" alt="">
                    </el-button>
                </div>
            </div>
            <div class="floor2">
                <div class="nav">
                    <el-row class="p-t-30">
                        <el-col :offset="1"  :span="2" class="label" >工作地点:</el-col>
                        <el-col  :span="20">
                            <el-radio-group  v-model="listQuery.city_id" @change="handleSelect">

                                <el-radio-button class="radio-border m-b-20" v-for="item in addressList"  :label="item.id"  :key="item.id">{{item.name}}</el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row class="p-t-10">
                        <el-col :offset="1"  :span="2"  style="height: 38px;line-height: 38px">职位类型：</el-col>
                        <el-col  :span="20">
                            <el-radio-group v-model="listQuery.cate_id" @change="handleSelect">
                                <el-radio-button class="radio-border m-b-20"  v-for="item in jobTypeList"  :label="item.id" :key="item.id">{{item.name}}</el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>
                    <el-row class="p-t-10">
                        <el-col :offset="1"  :span="2" style="height: 38px;line-height: 38px" >招聘类型：</el-col>
                        <el-col  :span="20">
                            <el-radio-group v-model="listQuery.type" @change="handleSelect">
                                <el-radio-button class="radio-border m-b-20"  v-for="item in typeList"  :label="item.id" :key="item.id">{{item.name}}</el-radio-button>
                            </el-radio-group>
                        </el-col>
                    </el-row>

                </div>
                <div v-if="!isShow" class="table">
                    <el-table
                            :data="tableData"
                            border

                            style="width: 100%;margin-top: 40px">
                        <el-table-column
                                label="职位名称"
                                align="center"
                        >
                            <template slot-scope="scope">
                                <el-button @click="showDetail(scope.row)" type="text" size="small">{{scope.row.position}}</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="招聘类型"
                                align="center"
                                width="200"
                        >
                            <template slot-scope="scope">
                                {{scope.row.type==1?'社会招聘':'校园招聘'}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="city"
                                label="工作地点"
                                align="center"
                                width="150"
                                >
                        </el-table-column>


                        <!-- <el-table-column
                                prop="department"
                                label="所在部门"
                                align="center"
                                width="200"
                                >
                        </el-table-column> -->
                    </el-table>
                    <pagination class="text-center" layout="prev, pager, next" v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.per_page" @pagination="handleSelect('page')" />

                </div>
                <div v-if="isShow" class="m-t-30 p-30  ">
                    <h3 class="fz-22">{{jobDetail.position}}</h3>
                    <el-row class="fz-16 p-t-10">
                        <el-col class="p-t-15" :span="6">招聘类型：{{jobDetail.type==1?'社会招聘':'校园招聘'}}</el-col>
                        <el-col class="p-t-15" :span="6" >薪资范围：{{jobDetail.salary}}</el-col>
                    </el-row>
                    <el-row class="fz-16 p-t-10">
                        <el-col class="p-t-15" :span="6" >工作性质：{{jobDetail.nature==1?'全职':'兼职'}}</el-col>
                        <el-col class="p-t-15" :span="6">招聘人数：{{jobDetail.num}}</el-col>
                        <el-col class="p-t-15" :span="6">截止时间：{{jobDetail.end_date}}</el-col>
                    </el-row>
                    <div class="box">
                        <h4>工作地点</h4>
                        <p>{{jobDetail.city}}</p>
                    </div>
                    <div class="box">
                        <h4>岗位职责</h4>
                        <div class="md markdown-body" v-html="jobDetail.task">{{jobDetail.task}}</div>
                    </div>
                    <div class="box">
                        <h4>任职资格</h4>
                        <div class="md markdown-body" v-html="jobDetail.ask">{{jobDetail.ask}}</div>

                    </div>
                    <div class="box">
                        <h4>投递简历</h4>
                        <p>
                            <a style="color: #0084CA" v-show="jobDetail.email" :href="'mailto:'+jobDetail.email">{{jobDetail.email}}</a>

                        </p>
                        <p style="margin-top: 10px">
                            <a style="color: #0084CA" v-show="jobDetail.link" target="_blank" :href="jobDetail.link">{{jobDetail.link}}</a>

                        </p>


                    </div>

                </div>

            </div>
        </div>

    </div>
</template>

<script>
    import {getList,getOptionList,getInfo} from "@/api/job"
    import Pagination from '@/components/Pagination' // secondary package based on el-pagination

    export default {
        name: "join",
        components: { Pagination },

        data() {
            return {
                listQuery: {
                    city_id:'',
                    cate_id:'',
                    type:'',
                    position:'',
                    page: 1,
                    per_page: 20,
                },
                total:0,

                address:'',
                addressList:[{
                    id:'',
                    name:'全部'
                }],
                jobType: '',
                jobTypeList: [
                    {
                        id:'',
                        name:'全部'
                    }
                ],
                type:'',
                typeList: [
                    {
                        id:'',
                        name:'全部'
                    },
                    {
                        id:'1',
                        name:'社会招聘'
                    },
                    {
                        id:'2',
                        name:'校园招聘'
                    },

                ],

                tableData: [],
                jobDetail:{},
                isShow:true,
            };
        },
        created: function () {
            this.getList(this.listQuery);
            this.getAddress();
            this.getJobType();

        },
        methods: {
            handleSelect (type) {
                this.isShow=false;
                if(type!='page'){
                    this.listQuery.page=1
                }
                let h =  document.documentElement.scrollTop
                this.$router.push({path:'/about/joinUs',query:this.listQuery})
                window.scrollTo(0,h);

            },
            getInfo (id) {
                getInfo({id}).then(res=>{
                    this.jobDetail=res.data
                })
            },
            /**
             * @method getList
             * @param {type} argName - description
             * @return {type} argName - description
             * @description: 获取列表
             */
            getList (obj) {

                // if(this.listQuery.id){
                //     this.listQuery.id= this.listQuery.id.match()
                // }
                // this.$router.push({path:'/about/joinUs'})

                getList(obj).then(res=>{
                    this.tableData=res.data.list
                    this.total=res.data.count
                })
            },
            /**
             * @method getType
             * @param {type} argName - description
             * @return {type} argName - description
             * @description: 获取职位类型
             */
            getJobType () {
                getOptionList({type:1}).then(res=>{
                    this.jobTypeList= this.jobTypeList.concat(res.data)
                })
            },

            /**
             * @method getOptionList
             * @param {type} argName - description
             * @return {type} argName - description
             * @description: 获取工作地点列表
             */
            getAddress () {
                getOptionList({type:3}).then(res=>{
                    this.addressList=this.addressList.concat(res.data)
                })
            },
            /**
             * @method showDetail
             * @param {type} argName - description
             * @return {type} argName - description
             * @description:
             */
            showDetail(jobInfo){
                this.$router.push({path:'/about/joinUs',query:{id:jobInfo.id}})
                this.jobDetail = jobInfo
                this.isShow=true;
            }
        },
        watch:{
            $route:
                {
                    handler:function (to) {
                       if(to.query.id){
                           this.isShow=true
                           this.getInfo(to.query.id)
                       }else {
                           this.isShow=false;
                           this.listQuery.city_id=to.query.city_id
                           this.listQuery.cate_id=to.query.cate_id
                           this.listQuery.type=to.query.type
                           this.listQuery.page=parseInt(to.query.page)
                           this.listQuery.position=to.query.position
                           if(to.query){
                               this.getList(to.query)
                           }

                       }
                   } ,
                    immediate: true
                }

        }
    }
</script>

<style scoped lang="less">
    .md/deep/p{
        line-height: 2.5;
    }
    .floor1{
        height: 270px;
        background: url('http://eos-guangzhou-1.cmecloud.cn/hefen/data/attachment/forum/202003/29/142704ev113rrwraair77u.png?AWSAccessKeyId=227IZ4ZAFROSWUNBMWGQ&Expires=9999044487&Signature=wJCYNlxL6B6pwgOa46w9nBFiQfI%3D') no-repeat;
        background-size: 100% 100%;
        .search{
            width: 517px !important;
            &/deep/input{
                border-radius: 0 !important;
                height: 48px;
            }
        }
    }
.floor2{
    padding: 20px 120px;
    .nav{
            .label{
                height: 40px;
                line-height: 40px;
        }
        .radio-border{
            padding-right: 20px;
            &.is-active/deep/span{
                background-color:  #0084CA
            }

            &/deep/span{
                border-radius: 0 !important;
                border: none;
                /*background-color: #F5F5F5;*/
            }
        }
    }
    .box{
        padding-top: 50px;
        h4{
            font-size: 22px;
            padding-bottom: 15px;
            font-weight: 400;
        }
    }

}
    .table/deep/.is-leaf{
        background-color: #F1F7FB;
    }
    .table{
        text-align: center !important;
    }
</style>
